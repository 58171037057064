import React from "react";
import {
	NavLink
} from "react-router-dom";

import {
	navlink_color,
	pages
} from "./content"

import "./App.scss";
import logo from "./assets/logo.svg";
import linkedin from "./assets/linkedin.svg";
import icon_menu from "./assets/icon_menu.svg";
import icon_cross from "./assets/icon_cross.svg";



interface Props_NavBarLink
{
	label:string,
	target:string,
	onClick:(event:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
}

class NavBarLink extends React.Component<Props_NavBarLink>
{
	render()
	{
		return (
			<div className="NavBarLink" style={this.props.label === "" ? { pointerEvents: "none"} : {}}>
				<NavLink to={this.props.target} activeStyle={{ color: navlink_color }} onClick={this.props.onClick}>
					{this.props.label}
				</NavLink>
			</div>
		);
	}
}



interface State_Nav
{
	display:boolean,
	mobile:boolean,
	mobile_menu:boolean,
}

class Nav extends React.Component<State_Nav, State_Nav>
{
	previous_scrollY:number = 0;

	constructor(props:State_Nav, state:State_Nav)
	{
		super(props, state);
		this.state =
		{
			display:		props.display,
			mobile:			props.mobile,
			mobile_menu:	props.mobile_menu,
		}
	}

	componentDidMount()
	{
		this.updateWindow();
		window.addEventListener("resize", this.updateWindow.bind(this));
		window.onscroll = this.handleScroll.bind(this);
	}
	componentWillUnmount()
	{
		window.removeEventListener("resize", this.updateWindow);
		window.onscroll = null;
	}

	handleScroll()
	{
		if (window.scrollY <= 80)
			this.setState({ display: true });
		else if (window.scrollY > this.previous_scrollY)
			this.setState({ display: false, mobile_menu: false });
		else if (window.scrollY < this.previous_scrollY)
			this.setState({ display: true });
		this.previous_scrollY = window.scrollY;
	};

	handleClick()
	{
		this.setState({ mobile_menu: !this.state.mobile_menu });
	}

	handleNavLink()
	{
		if (this.state.mobile)
			this.setState({ mobile_menu: false });
	}

	updateWindow()
	{
		if (window.innerWidth <= 800)
			this.setState({ mobile: true });
		else
			this.setState({ mobile: false });
	}

	render()
	{
		let header:any;
		if (this.state.mobile)
		{
			header = (
				<div style={{ pointerEvents: "auto" }}>
					<button onClick={this.handleClick.bind(this)}>
						<img src={(this.state.mobile_menu ? icon_cross : icon_menu)}
							alt={(this.state.mobile_menu ? "Exit": "Menu")}
							style={{ color: "#FFFFFF" }}/>
					</button>
					<div className={"Header NavBar" + (this.state.mobile_menu ? " Display" : "")}>
						<div id="link0"><NavBarLink target={pages[0]}	label={"About"}			onClick={this.handleNavLink.bind(this)}/></div>
						<div id="link1"><NavBarLink target={pages[1]}	label={"Overview"}		onClick={this.handleNavLink.bind(this)}/></div>
						<div id="link2"><NavBarLink target={pages[2]}	label={"Research"}		onClick={this.handleNavLink.bind(this)}/></div>
						<div id="link3"><NavBarLink target={pages[3]}	label={"Publications"}	onClick={this.handleNavLink.bind(this)}/></div>
						<div id="link4"><NavBarLink target={pages[4]}	label={"Services"}		onClick={this.handleNavLink.bind(this)}/></div>
						<div id="link5"><NavBarLink target={pages[5]}	label={"Contact"}		onClick={this.handleNavLink.bind(this)}/></div>
						<a href="https://www.linkedin.com/company/axiomavox/about/"
							target="_blank" rel="noopener noreferrer"
							title="AxiomaVox on Linkedin"
							style={{ border: "none" }}>
							<img src={linkedin} alt="Linkedin" width="32" height="32"/>
						</a>
					</div>
				</div>
			);
		}
		else
		{
			header = (
				<div className="Header NavBar">
					<NavBarLink target={pages[0]}	label={"About"}			onClick={this.handleNavLink.bind(this)} />
					<NavBarLink target={pages[1]}	label={"Overview"}		onClick={this.handleNavLink.bind(this)} />
					<NavBarLink target={pages[2]}	label={"Research"}		onClick={this.handleNavLink.bind(this)} />
					<NavBarLink target=""			label=""				onClick={this.handleNavLink.bind(this)} />
					<NavBarLink target={pages[3]}	label={"Publications"}	onClick={this.handleNavLink.bind(this)} />
					<NavBarLink target={pages[4]}	label={"Services"}		onClick={this.handleNavLink.bind(this)} />
					<NavBarLink target={pages[5]}	label={"Contact"}		onClick={this.handleNavLink.bind(this)} />
				</div>
			);
		}
		return (
			<header style={this.state.mobile_menu ?
				{ top: (this.state.display ? "0" : "-100vh"), height: "100vh" } :
				{ top: (this.state.display ? "0" : "-100vh") }}>
				<div className="AppLogo">
					<img src={logo} alt="Logo" />
				</div>
				{header}
			</header>
		);
	}
}

export default Nav;
