import React from "react";
import {
	HashRouter as Router,
	withRouter,
	RouteComponentProps,
	Route,
	Switch,
	NavLink,
	Redirect
} from "react-router-dom";

import Nav from "./Nav"
import {
	content_About,
	content_Overview,
	content_Research,
	content_Publications,
	content_Services,
	content_Contact,
	content_Legal,
	content_NoPage,
	email_link,
	navlink_color,
	pages
} from "./content"

import "./App.scss";
import logo from "./assets/logo.svg";
import linkedin from "./assets/linkedin.svg";



function About()		{	return (<div>{content_About}</div>);		}
function Overview()		{	return (<div>{content_Overview}</div>);		}
function Research()		{	return (<div>{content_Research}</div>);		}
function Publications()	{	return (<div>{content_Publications}</div>);	}
function Services()		{	return (<div>{content_Services}</div>);		}
function Contact()		{	return (<div>{content_Contact}</div>);		}
function Legal()		{	return (<div>{content_Legal}</div>);		}
function NoPage()		{	return (<div>{content_NoPage}</div>);		}



interface Props_FooterLink
{
	label:string,
	target:string,
}

class FooterLink extends React.Component<Props_FooterLink>
{
	render()
	{
		return (
			<div className="FooterLink" style={this.props.label === "" ? { pointerEvents: "none" } : {}}>
				<NavLink to={this.props.target} activeStyle={{ color: navlink_color }}>
					{this.props.label}
				</NavLink>
			</div>
		);
	}
}



export const ScrollToTop = withRouter(
	class ScrollToTopWithoutRouter extends React.Component<RouteComponentProps<any>>
	{
		componentDidUpdate(prevProps: Readonly<RouteComponentProps<any>>)
		{
			if (this.props.location !== prevProps.location)
			{
				window.scrollTo(0, 0)
			}
		}

		render()
		{
			return (null);
		}
	}
);



const App: React.FC = () =>
{
	return (
		<Router>
			<ScrollToTop />
			<div className="App">
				<div className="HeaderBG"></div>
				<Nav display={true} mobile={false} mobile_menu={false} />
				<Switch>
					<Redirect exact from="/" to={pages[0]} />
					<Route exact path={pages[0]}	component={About}		 />
					<Route exact path={pages[1]}	component={Overview}	 />
					<Route exact path={pages[2]}	component={Research}	 />
					<Route exact path={pages[3]}	component={Publications} />
					<Route exact path={pages[4]}	component={Services}	 />
					<Route exact path={pages[5]}	component={Contact}		 />
					<Route exact path={pages[6]}	component={Legal}		 />
					<Route component={NoPage} />
				</Switch>
				<footer>
					<img src={logo} alt="Logo" />
					<p style={{ color: "#FFFFFF" }}>
						<br/>172 rue de L'Université
						<br/>75007 PARIS
						<br/>
						<br/>01 71 24 96 41
						<br/>
						<br/><span style={{ color: "#FFFFFF", border: "none" }}>{email_link}</span>
						<br/>
						<br/><a href="https://www.linkedin.com/company/axiomavox/about/"
							target="_blank" rel="noopener noreferrer"
							title="AxiomaVox on Linkedin"
							style={{ border: "none" }}>
							<img src={linkedin} alt="Linkedin" width="32" height="32"/>
						</a>
					</p>
					<div className="Footer NavBar">
						<FooterLink target={pages[0]}	label={"About"}			/>
						<FooterLink target={pages[1]}	label={"Overview"}		/>
						<FooterLink target={pages[2]}	label={"Research"}		/>
						<FooterLink target={pages[3]}	label={"Publications"}	/>
						<FooterLink target={pages[4]}	label={"Services"}		/>
						<FooterLink target={pages[5]}	label={"Contact"}		/>
					</div>
					<div className="FooterLink Legal">
						<NavLink to={pages[6]} activeStyle={{ color: "#FFFFFF" }}>
							Legal Notice
						</NavLink>
						<span style={{ color: navlink_color }}>
							&nbsp;&mdash;&nbsp;AxiomaVox&nbsp;&copy;&nbsp;2021
						</span>
					</div>
				</footer>
			</div>
		</Router>
	);
}

export default App;
