import React from "react";



export const navlink_color:string = "#888888";



const email_user:string = "contact";
const email_domain:string = "axiomavox";
export const email_link = (<a href={"mailto:" + {email_user} + "@" + {email_domain} + ".org"}>{email_user}@{email_domain}.org</a>);



export const pages:string[] =
[
	"/about",
	"/overview",
	"/research",
	"/publications",
	"/services",
	"/contact",
	"/legal",
];



export const content_About =
(
<div style={{ position: "relative", height:"100%" }}>
	<div className="Background Left">

	</div>
	<div className="PageTitle Left">
		About Us
	</div>
	<div className="TextBox Right">
		<p>
			<br/>AxiomaVox is an independent research lab based in Paris. Our work aims to provide new insights into public debate matters, through the mass collection of data and improved processing means combined with a unique, system-based analysis framework for decision-making.
			<br/>
			<br/>Our research focuses on the question of how various cognitive agents generate, perceive, process, communicate and employ meaningful information. Our work emphasises cross-disciplinary cooperation with increased mutualisation means. We strive towards a strong empirical basis, by designing experimental projects to test hypotheses. To do so, we collect data from the behavioural response to controlled parameter changes, with our partner project factory <a href="https://cerebro.link/studio/" style={{ border: "none" }}>CerebroLink</a>.
			<br/>
			<br/>We spontaneously publish papers on various related topics, respond to commissions, and conduct fundamental research on areas at the crossroads of several technical fields: mathematics, formal epistemology, logics, social science, law...
			<br/>
			<br/>This work has potential applications in AI, predictive analysis, decision-making tools, automated decision-making, smart contracts, etc.
		</p>
	</div>
</div>
);



export const content_Overview =
(
<div>
	<div className="Background Center">

	</div>
	<div className="PageTitle Center" style={{ top: "15vmax" }}>
		Overview
	</div>
	<div className="TextBox Center" style={{ paddingTop: "30vmax" }}>
		<p>
			The question of meaning, as translated in formal epistemology models and as observed in behavioural analysis, may be divided into three aspects:
		</p>
		<ul>
			<li>Subjective meaning (how actors perceive information)</li>
			<li>Objective meaning (how one builds a rigorous epistemological framework to evaluate information)</li>
			<li>Communication (how actors build a common language to mutually transfer and process information)</li>
		</ul>
		<p>
			<br/>In light of the difficulties in finding a coherent solution to each of these three sub-problems individually, we have come to believe them to be inextricably linked. What follows is an overview of the different fields and methods from which we take inspiration in our research, and how we combine them to build a synthetic analytical framework.
		</p>
		<h3>I. Natural Language Processing &amp; Distributional Semantics</h3>
		<br/>
		<p>
			A first problem to solve is understanding words, the unit of meaning. At this point, there is a general inability to formalize semantic data, and consequently the structure of reasoning in textual information. Aided by various demonstrations in cognitive science, we have based our word models by how words are linked semantically to other words. This includes lexical links and syntactic links. This allows us to extract measurable semantical data from any given sample of text. We use traditional NLPs algorithms as well, and are working on custom ones which will probably be the subject of future publications.
		</p>
		<br/>
		<h3>II. Statistics &amp; Topological Data Analysis</h3>
		<br/>
		<p>
			Our goal here is to improve human understanding of complex, high-dimensional, noisy data - through improved tools of data segmentation, data visualization, and data exploration.
			We have found the questions of "qualifying" and "quantifying" meaning to be a core difficulty.
			In terms of "quantification", we have found information theory to provide some insight into measuring meaningfulness.
			It has proved relevant in analyzing consequences of information transfer on belief and decision-making systems.
			There is however a lot more work to be done on this front.
		</p>
		<br/>
		<p>
			Perhaps more importantly, when it comes to "qualifying" meaning, traditional statistical tools have provided many ways of processing large dimensional spaces and providing graspable models for scientists to understand complex (textual) data. Of note, one also has Bayesian statistics in the modelling of belief. However, if recent advances in AI and machine learning have taught us anything, it's that the most complex data tend to have underlying structure, and that most traditional statistical tools are unfit in making sense of said structure. As an example, in the words of Gunnar Carlsson, clustering methods tend to be a "one-trick poney", which often artificially break up relevant structure.
		</p>
		<br/>
		<p>
			We have found Topological Data Analysis (which we see as understanding data spaces as topological manifolds, allowing for more concise, structural, and local approaches to complexity reduction), mixed with traditional dimensionality reduction tools such as PCA, MDS, etc., to be especially promising in providing insight into complex data spaces. We combine it with, and apply it to, many modern techniques in machine learning.
		</p>
		<br/>
		<h3>III. Category Theory</h3>
		<br/>
		<p>
			The third problem we try to solve is that of interpersonal epistemological rigor. We have observed that most interdisciplinary efforts, although generally fruitful, suffer generally from one or two biases, both linked to technical communication. Either the subject studied is large, and it is difficult for members of each field to communicate effectively at a high level of technical detail and rigor; or the subject studied is very limited in scope, and the details are considered, but even then transversal vocabulary is limited.
		</p>
		<br/>
		<p>
			Confronted with this "over-specialization" of scientific domains, we see it as an important task to provide a universal, scientifically verifiable language for interdisciplinary communication. The most promising lead we have considered is category theory, and particularly applied category theory. The very nature of category theory is to characterize objects, as well as the spaces and the links they have with each other, in a universal formal language.
		</p>
		<br/>
		<p>
			We use it in many ways. First, to find (and prove) potential isomorphisms between fields of research to distinguish where improved communication and collaboration would be most fruitful. We also use it to find ways to formalize fields that weren't mathematically formal beforehand and build up a common language, while maintaining a high level of communication of technical detail. Finally, we'd like to use it to build or implement novel proof systems through homotopy type theory.
		</p>
		<br/>
		<h3>IV. Epistemology &amp; Logic</h3>
		<br/>
		<p>
			We have observed a lack of generalized mathematical structure efficiently describing the logic and epistemic models behind various types of decision-making processes. Because there is such a diversity in how agents process information, we have sought to employ and evaluate many of the most used logics. This can be thought of as a form of comparative epistemology.
		</p>
		<br/>
		<p>
			We use various tools, ranging from belief revision theories to modal logics, intuitionistic hybrid logic, dynamic epistemic logic and Kripkean semantics. This allows us to formalize epistemic changes in terms of logic in reaction to certain types of events, e.g. public announcement. For example, Kripkean semantics, which consists in a formal way to look at the epistemic state (as a "possible worlds" model) of a decision-making agent. Through the syntactic accessibility of various postulated worlds, Kripkean semantics allows for us to compute and formalize for each agent the consequences of its decisions, given the sum of information available. This provides mutual feedback between agents, beliefs, and information.
		</p>
		<br/>
		<p>
			All the logics we use have in common the fact that they are ways of proposing theories, as well as formal tools, to describe reasoning, information processing, and changes in the epistemic states of agents. This allows us to provide an abstract framework for the translation of meaning into simpler Boolean algebra models.
		</p>
		<br/>
		<h3>V. Social sciences</h3>
		<br/>
		<p>
			All-in-all, we wish our models to be applicable to solve real-world social and political problems. However, given the lack of formal methods in the humanities, as well as the lack of a comparative approach to evaluate the epistemic rigor of various ways of answering problems in certain fields, we've had to confront ourselves with various important tools.
		</p>
		<br/>
		<p>
			We wish to make sure that our axiomatic bases and the tools we use are in accord with the problems at hand, and that our models are consistent, as well provide experiments to run to measure the efficacy of our methods and provide feedback. We have taken an interest especially in game theory, behavioral economics, social network analysis, and computational law.
		</p>
		<br/>
		<p>
			We combine these domains and tools in the hope of creating a synthetic epistemological framework. Our goal is to make the arcanes of mathematics (which today hold a potential even greater than they ever have before) available to other sciences. We hope it shall provide the means of formalization of information, but also of communication, as well as automation of information processing and scientific research.
		</p>
		<p>
			<br/>Our main long-term goals can be summarized as follows:
			<br/>
			<br/>
		</p>
		<ol>
			<li>Create a formal language that functions rigorously across fields of knowledge, thus creating increased mutualization means at the service of a more transversal approach of complex issues (thereby allowing for detail-preserving transdisciplinary communications);</li>
			<li>Identify new collaboration perspectives between fields of research through an approach mostly founded in topology and category theory, which could be qualified as "comparative epistemology";</li>
			<li>Improve human understanding of the common abstract structures behind decision-making heuristics and information processing in all cognitive agents.</li>
		</ol>
		<p>
			<br/>We believe that this endeavour may open new perspectives in:
			<br/>
			<br/>
		</p>
		<ul>
			<li>Predictive analytics for research, business and administration (a current use case we are working on being computational law);</li>
			<li>Explainable AI (XAI)/Pedagogical AI (AI that explains its process, rather than being a black box);</li>
			<li>The implementation of supervised ML frameworks that combine a good predictive accuracy with an underlying understanding of the mechanisms it studies (ie, an improvement of machines to human-like reasoning);</li>
			<li>Fundamental research in mathematics and applied mathematics (by providing a better ecosystem for the intersection of logic, epistemology and (applied) category theory);</li>
			<li>Potential other applications include: smart contracts, scientific communication, automated scientific visualization software, automated decision-making/monitoring systems (a machine that knows when it can trust itself and when it should ask for advice), human-machine interfacing, etc.</li>
		</ul>
	</div>
</div>
);



export const content_Research =
(
<div>
	<div className="Background Right">
		
	</div>
	<div className="PageTitle Right">
		Research
	</div>
	<div className="TextBox Left">
		<p>
			<br/>Our research consists in understanding and modelling how information is created and processed in various domains, in order to provide valuable insight into decision-making practices and their consequences.
			<br/>
			<br/>To attain these goals, we are currently focusing on:
		</p>
		<ul>
			<li>Connecting mathematics and epistemic logic to provide models for decision-making heuristics;</li>
			<li>Elaborating a trustworthy synthetic epistemological framework with information processing and decision-making at its core;</li>
			<li>Improving available tools for the handling of complex, noisy, structural, high-dimensional data - notably general textual data;</li>
			<li>Elaborating a system of structural mappings to compare fields of knowledge: use of varied topological perspectives at the service of a practical comparative epistemology.</li>
		</ul>
		<p>
			<br/>
			<br/>Our work involves, but is not limited to:
			<br/>
		</p>
		<h3>I. Natural Language Processing &amp; Distributional Semantics</h3>
		<br/>
		<ul>
			<li>Different definitions of surprisal and entropy combined with topological data analysis to quantify and explain semantic value;</li>
			<li>Algorithm design for discourse feature extraction (using bijection mappings to build universal functional semantics);</li>
			<li>Applied distributional semantics to formalize concepts as sets of statistical relationships with others in building data point clouds;</li>
			<li>TDA applied to NLP (particularly for word disambiguation and local context mapping);</li>
			<li>Persistent homology for NLP and AI.</li>
		</ul>
		<br/>
		<h3>II. Statistics &amp; Topological Data Analysis</h3>
		<br/>
		<ul>
			<li>Building topological regression and clustering algorithms using Reeb graphs and Morse-Smale complexes, combined with non-linear or spectral dimensionality reduction techniques, and applying them to social network embeddings as well as technical textual data;</li>
			<li>High-dimensional manifold learning approaches with L-Isomaps and home-made algorithms based on piecewise-linear manifolds and neural networks;</li>
			<li>Cancellation of critical points as an efficient, homology-preserving means of handling high-dimensional data;</li>
			<li>Determining a version of Morse-Smale complexes that builds upon the current understanding of discrete gradient fields;</li>
			<li>Discrete Morse Theory for multidimensional data;</li>
			<li>Statistical analysis of topological persistence diagrams;</li>
			<li>Statistical manifold learning using spectral dimensionality reduction to determine a link with formal logics in the context of dynamic systems;</li>
			<li>Comparative statistical analysis using various ML-related models (SVM, naive Bayes, CNN, RNN, GAN, autoencoders, SOM, …), explanatory feature extraction from learned manifolds.</li>
		</ul>
		<br/>
		<h3>III. Category Theory</h3>
		<br/>
		<ul>
			<li>Dynamic Partial Order Reduction for model checking to partition context and depth boundings;</li>
			<li>Category Theory as a general standard for proofs and presentation in our research;</li>
			<li>Applied category theory for improved modelling in social science;</li>
			<li>Exploration of proof theory and applied category theory to increase the number of uses cases for which automated formal proof systems can be employed.</li>
		</ul>
		<br/>
		<h3>IV. Epistemology &amp; Logic</h3>
		<br/>
		<ul>
			<li>Dynamic Epistemic Logic's "model transformer" in the sense of a graph product of Kripke actions to define knowledge-based points in the context of a dynamical system;</li>
			<li>Propositional Dynamic Logic (that is, if a polynomial time algorithm for resolution, beyond model checking, can be found);</li>
			<li>SMT and SAT solvers (applying the DPLL algorithm) in the context of Boolean structures, thus determining a model checking approach with partial order reduction and abstraction methods;</li>
			<li>The building of a suitable model combining modal logics, description logic, intuitionistic logic and temporal logics.</li>
		</ul>
		<br/>
		<h3>V. Social sciences</h3>
		<br/>
		<ul>
			<li>Creating appropriate datasets for applications of our models to computational law;</li>
			<li>Behavioural economics and game theory as a means of formalizing and eliciting stable strategies for decision-making agents;</li>
			<li>Social network analysis;</li>
			<li>Emotional quantification and reasoning models;</li>
			<li>Decision theory.</li>
		</ul>
		<br/>
		<br/>
		<br/>
		<br/>
		<hr style={{ color:"#888888" }} />
		<br/>
		<br/>
		<br/>
		<p>
			<br/>We are a young laboratory, and are looking for more contributors and collaborators.
			All of our resident members have a transversal profile, developing interest in various fields of research while maintaining specialisation.
			We are looking for individuals with similar interest in our subjects of research, wishing to contribute or collaborate, with experience in the following fields:
			<br/>
			<br/>
		</p>
		<span style={{ textAlign:"left" }}>
			<ul>
				<li>Category theory, Applied Category Theory;</li>
				<li>Type Theory, Lambda-type calculus, Homotopy-Type Theory;</li>
				<li>Universal algebra, Abstract Algebra;</li>
				<li>Proof theory, Metamathematics;</li>
				<li>Probability, Statistics, Measure Theory;</li>
				<li>Natural Language Processing;</li>
				<li>Machine Learning, Deep Learning, Data Science, Data Mining, Data Augmentation, Data Poisoning;</li>
				<li>Topological Data Analysis, (Discrete) Morse Theory;</li>
				<li>Algebraic topology;</li>
				<li>Statistical Mechanics, Information theory, Thermodynamics;</li>
				<li>Differential Geometry, Geometric Algebra, Geometric Calculus, Information Geometry;</li>
				<li>Theories of differential equations, Lie theory, Dynamical systems, Ergodic Theory, Chaos Theory, Game Theory;</li>
				<li>Dynamic Epistemic Logic, Propositional Dynamic Logic;</li>
				<li>Kripke semantics;</li>
				<li>Linear Temporal Logic, Computational Tree Logic;</li>
				<li>Mu-calculus;</li>
				<li>Algorithmic Complexity Theory;</li>
				<li>Language Theory;</li>
				<li>Satisfiability (SAT, SMT);</li>
				<li>(Dynamic) Partial Order Reduction;</li>
				<li>Neuroscience, Cognitive Science, Behavioral Psychology;</li>
				<li>Numerical Analysis, sparse matrix methods, parallel computing, distributed computing;</li>
				<li>Distributional semantics, computational semantics;</li>
			</ul>
			<p>
				<br/>
				<br/>You can apply or ask any question you might have about our work by contacting this address:
				<br/>{email_link}
			</p>
		</span>
	</div>
</div>
);



export const content_Publications =
(
<div>
	<div className="PageTitle Center CardsTitle">
		Publications
	</div>
	<div className="Deck">
		<div className="Card">
			<div className="Title">
				<h2>Thermoeconomics: from common concepts to a common paradigm?</h2>
			</div>
			<br/>
			<h1>
				<br/>Thermoeconomics (and then econophysics) appear as one of the leading examples of an attempt at building models based on formal homologies between fields of knowledge: that is, the functional analogy determining the adequation of certain methods with certain problems, beyond the limits of traditional fields of research.
				<br/>
				<br/>
				<br/>The aim of this paper is to draw some lessons on this historical attempt at bridging disciplines to better explain different phenomena: what was its objective? To what extent did it fail or succeed? What were the limitations of such attempt? Were they intrinsic?
				<br/>
				<br/>Why do we believe that deeper connections could be made? What different approach do we propose, compared to such attempts? What are the epistemological and practical implications of the theory we develop, compared to existing approaches?
				<br/>
				<br/>
				<br/>Our approach will be illustrated by an example of the formal similarity between certain types of equations, the applications of which extend beyond economics to the study of dynamical systems and interactions.
				<br/>
			</h1>
			<div className="Tags">
				<span style={{ backgroundColor: "#001941" }}>Work In Progress</span>
			</div>
		</div>
		<div className="Card">
			<div className="Title">
				<h2>User experience and communication conditioning: perspectives on language and data science</h2>
			</div>
			<br/>
			<h1>
				<br/>What is communication? How do individuals define themselves and perceive others? What isomorphic structures may exist between means of self-expression?
				<br/>
				<br/>Communication seems to be defining our societies' possibilities. It has an intimate connection to language and cognition and is an important current issue. We aim to explore the consequences of behavioural conditioning on communication and its limits. In doing so, we propose to design an experiment to observe behavioural responses to controlled parameter changes and to measure how they affect communication between users of online platforms.
				<br/>
				<br/>Our aim is threefold:
			</h1>
			<ol style={{ color: "#444444" }}>
				<li>Elaborate on new ways to assess the standard practices in user experience design;</li>
				<li>Explore the structural correspondences between different means of expression when adding various constraints;</li>
				<li>Encourage research on communication across different media to better understand the cognitive patterns that underlies these exchanges.</li>
			</ol>
			<h1>
				<br/>
				<br/>We thus propose a platform for gathering and interpreting data to determine useful connections that would allow for a more comprehensive understanding of user perception and communication.
				<br/>
			</h1>
			<div className="Tags">
				<span style={{ backgroundColor: "#001941" }}>Work In Progress</span>
			</div>
		</div>
		<div className="Card">
			<div className="Title">
				<h2>Synthetic epistemology framework</h2>
			</div>
			<br/>
			<h1>
				<br/>In this series of papers, we aim to present a synthetic epistemology model that is applicable to the study of decision-making processes and that of semantic and belief representation.
				<br/>
				<br/>Such a synthetic framework opens new collaboration perspectives through increased means for mutualisation and comparison of data across disciplines.
				<br/>It provides new insights into behavioural analysis that accounts for the structure of reasoning and belief changes in particular, for various cognitive agents, thus having direct applications in predictive analysis and AI.
				<br/>Furthermore, this may constitute a major decision-making tool in domains that have traditionally been associated with social sciences.
				<br/>
				<br/>
				<br/>In doing so, we combine natural language processing techniques with distributional semantics, statistics, topological data analysis, category theory, Kripke semantics, information theory, cognitive science, social sciences, as well as different elements from formal epistemology and logic.
				<br/>
			</h1>
			<div className="Tags">
				<span style={{ backgroundColor: "#001941" }}>Work In Progress</span>
				<span style={{ backgroundColor: "#777777" }}>Series</span>
			</div>
		</div>
	</div>
</div>
);



export const content_Services =
(
<div>
	<div className="PageTitle Left">
		Services
	</div>
	<div className="TextBox Right">
		<p>
			<br/>AxiomaVox responds to various commissions, depending on the estimated added value that we may bring about and our interest in the proposed topic.
			<br/>
			<br/>These commissions may regard topics such as predictive analysis in law, political strategy based on semantic representation, knowledge representation for education (both general and technical)…
			<br/>
			<br/>Our approach consists in applying the methods presented <a href="#/research">here</a> to the analysis of datasets (either provided by the commissioner or built by our team on the basis of publicly available information). We adapt these techniques to the particular needs of the situation considered, in order to translate a panel of possible alternative decisions for agents into measurable behavioural responses (and their impact on mental representations), by formalising them as parameter changes.
			<br/>
			<br/>We pay particular attention to the elaboration of detailed reports that compare options and explain the underlying mechanisms at stake, thus combining quantitative and qualitative approaches to problem-solving.
			<br/>
			<br/>We may resort to the design of specific experimental projects to collect data better-suited for our work in fundamental research, with our partner project factory, CerebroLink.
		</p>
	</div>
</div>
);



export const content_Contact =
(
<div>
	<div className="PageTitle Left" style={{ left:"5vw" }}>
		Contact Us
	</div>
	<div className="TextBox Right">
		<p>
			<br/>We are particularly looking for contributors in the fields described <a href="#/research">here</a>. Contributions may take tailor-made forms.
			<br/>You may apply to join the team by writing to: {email_link}.
			<br/>
			<br/>Donations can be made to our organisation through the following link:
			<br/>
			<br/>
			<br/>
		</p>
		<form style={{ textAlign: "center" }} action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
			<input type="hidden" name="cmd" value="_s-xclick" />
			<input type="hidden" name="hosted_button_id" value="U5YH9TJBDRQHS" />
			<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
			<img alt="" src="https://www.paypal.com/en_FR/i/scr/pixel.gif" width="1" height="1" />
		</form>
	</div>
</div>
);



export const content_Legal =
(
<div>
	<div className="PageTitle Center">
		Legal Notice
	</div>
	<div className="TextBox Center">
		<p>
			In accordance with applicable legal regulations for data protection, users of <a href="www.axiomavox.org">www.axiomavox.org</a> are informed of the following: Rodolphe Saur owns this website, on behalf of AxiomaVox.
		</p>
		<br/>
		<h3>Purpose and Quality</h3>
		<p>
			The website <a href="www.axiomavox.org">www.axiomavox.org</a> aims at providing extensive information on the activities of the group.
			AxiomaVox endeavours to offer quality and precise information; however, this information is subject to change, and is not exhaustive.
			<br/>We cannot be held responsible for any inaccuracies or omissions that may occur.
			<br/>You may contact us, if any piece of information seems wrong, incomplete, or contains typographical errors.
			<br/>No personal data is collected without your knowledge.
		</p>
		<br/>
		<h3>Identification</h3>
		<p>
			<br/>This website’s hosting company is: OVH, a Simplified Joint Stock Company.
			<br/>Its head office is located 2 rue Kellermann, 59100 Roubaix, France.
		</p>
		<br/>
		<h3>Web Analytics</h3>
		<p>
			In order to better understand our visitors, we measure (anonymously), the number of visits, the number of viewed pages, the internet traffic source, visitors activity as well as their return frequency, via Google Analytics solutions.
			For more information, or if you do not wish to be audited, please report to <a href="https://support.google.com/analytics/answer/6004245?hl=en">their confidentiality section</a>.
		</p>
		<br/>
		<h3>Credits and Copyright</h3>
		<p>
			The owner of this website owns property rights, or is allowed to use all of the content featured or displayed on the website, including source code, all graphic content, logotype, text and image.
			<br/>
			<br/>Any reproduction, reuse, publication, modification, distribution of <a href="www.axiomavox.org">www.axiomavox.org</a> content, without prior written authorization from AxiomaVox ({email_link} for any request), is strictly prohibited under penalty of prosecution.
			<br/>
			<br/>In case of contestation, litigation or disputes over these terms, only French law will apply.
		</p>
	</div>
</div>
);



export const content_NoPage =
(
<div>
	<div className="PageTitle Left">
		404
	</div>
	<div className="TextBox Right">
		<p>
			<br/>Error 404 - Page not found
			<br/>
			<br/>
			<br/>
			<h3>The current URL does not match any existing page.</h3>
		</p>
	</div>
</div>
);
